<template>
  <div class="workOrder">
    <a-form-model ref="ruleForm" :model="formquery" v-bind="layout">
      <a-form-model-item
        label="工单标题"
        prop="title"
        :key="1"
        :rules="[{ required: true, message: '请输入工单标题', trigger: 'change' }]"
      >
        <a-input placeholder="请输入工单标题" v-model.trim="formquery.title" />
      </a-form-model-item>
      <a-form-model-item
        label="问题类型"
        prop="type"
        :key="2"
        :rules="[{ required: true, message: '请选择问题类型', trigger: 'change' }]"
      >
        <el-radio-group v-model="formquery.type" size="medium">
          <el-radio label="1" border>后台操作</el-radio>
          <el-radio label="2" border>SDK接入</el-radio>
          <el-radio label="3" border>需求反馈</el-radio>
          <el-radio label="4" border>其他问题</el-radio>
        </el-radio-group>
      </a-form-model-item>
      <!-- 后台操作展示菜单 -->
      <template v-if="formquery.type === '1'">
        <a-form-model-item
          label="菜单"
          prop="menuList"
          :rules="[{ required: true, message: '请选择菜单', trigger: 'change' }]"
        >
          <a-checkbox-group v-model="formquery.menuList">
            <a-checkbox v-for="(item, index) in menuArr" :key="index" :value="item.value">
              {{ item.name }}
            </a-checkbox>
          </a-checkbox-group>
        </a-form-model-item>
      </template>
      <!-- 操作系统展示 -->
      <template v-if="formquery.type === '2'">
        <a-form-model-item
          label="操作系统"
          prop="menuList"
          :rules="[{ required: true, message: '请选择操作系统', trigger: 'change' }]"
        >
          <a-checkbox-group v-model="formquery.menuList" name="radioGroup">
            <a-checkbox v-for="(item, index) in menuArr1" :key="index" :value="item.value">
              {{ item.name }}
            </a-checkbox>
          </a-checkbox-group>
        </a-form-model-item>
        <a-form-model-item
          label="SDK版本"
          prop="sdkVersion"
          :rules="[{ required: true, message: '请输入sdkVersion', trigger: 'blur' }]"
        >
          <a-input placeholder="请输入sdkVersion" v-model.trim="formquery.sdkVersion" />
        </a-form-model-item>
      </template>
      <a-form-model-item
        label="问题描述"
        prop="description"
        :rules="[{ required: true, message: '请输入问题描述', trigger: 'blur' }]"
      >
        <template>
          <el-input
            type="textarea"
            placeholder="请输入问题描述"
            v-model="formquery.description"
            maxlength="2000"
            show-word-limit
            rows="6"
          />
        </template>
      </a-form-model-item>
      <a-form-model-item
        label="附件"
        :rules="[
          {
            required: false,
            validator: (rule, value, callback) => {
              videoUrlValidator(rule, value, callback, index)
            },
            trigger: ['change']
          }
        ]"
      >
        <a-upload
          name="file"
          :multiple="true"
          :action="uploadUrl"
          :headers="headers"
          accept=".png,.jpg,.jpg,.webp,.zip,.rar"
          @change="handleChange"
          v-model="fileList"
          :before-upload="beforeUpload"
          :show-upload-list="{ showRemoveIcon: true }"
        >
          <div class="cloudUpload">
            <a-icon type="cloud-upload" style="font-size: 40px"></a-icon>
            <p>点击上传附件</p>
          </div>
        </a-upload>
        <div class="uplodDesc">
          <div>可支持格式：png、jpg、jpg、webp、zip、rar</div>
          <div>文件大小要求不超过30M，最多上传5个</div>
          <div>截图请尽量完整清晰</div>
        </div>
      </a-form-model-item>
    </a-form-model>
    <a-button class="submit" type="primary" @click="handleSubmit">提交</a-button>
  </div>
</template>

<script>
import { deviceMixin } from '@/store/device-mixin'
import { mapState } from 'vuex'
import { getAddworkOrder } from '@/api/workOrder'

export default {
  components: {},
  mixins: [deviceMixin],
  data () {
    return {
      layout: {
        labelCol: {
          span: 6
        },
        wrapperCol: {
          span: 14
        }
      },
      fileList: [],
      formquery: {
        fileUrlList: []
      },
      menuArr: [
        { name: '首页', value: '1' },
        { name: '媒体管理', value: '2' },
        { name: '聚合管理', value: '3' },
        { name: '广告管理', value: '4' },
        { name: '高级功能', value: '5' },
        { name: '其他', value: '6' }
      ],
      menuArr1: [
        { name: 'Android', value: '1' },
        { name: 'IOS', value: '2' }
      ],
      uploadUrl: process.env.VUE_APP_API_BASE_URL + 'common/upload',
      fileSize: [],
      fileSizes: null
    }
  },
  computed: {
    headers () {
      return {
        Authorization: this.token
      }
    },
    ...mapState({
      token: (state) => state.user.token,
      // 动态主路由
      mainMenu: (state) => state.permission.addRouters,
      loginIcon: (state) => state.autoweb.loginIcon
    })
  },
  mounted () {},
  beforeDestroy () {},
  methods: {
    // 点击问题类型
    clickTab (data) {
      this.formquery.type = data
    },
    beforeUpload (file, fileList) {
      if (fileList.length > 5) {
        this.fileList.splice(0, 1)
        this.$message.error('最多上传5个文件！')
      }
    },
    handleChange (info) {
      if (info.file.status === 'done') {
        this.fileList.push(info.file.response.data)
        this.formquery.fileUrlList = this.fileList
        this.fileSize.push(info.file.size)
        for (let i = 0; i < this.fileSize.length; i++) {
          this.fileSizes += this.fileSize[i]
        }
        const length = this.formquery.fileUrlList.length > 5
        if (length) {
          this.$message.error('最多上传5个文件！')
        }
        const isLt2M = this.fileSizes / 1024 / 1024 < 30
        if (!isLt2M) {
          this.$message.error('文件大小应小于30M!')
        }
        return isLt2M && length
      }
    },
    // 点击确定
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const { type = '', title = '', menuList = [], description = '', fileUrlList = [] } = this.formquery
          const res = await getAddworkOrder({
            type,
            title,
            menuList,
            description,
            fileUrlList
          })
          if (res.code === 200) {
            this.$router.back()
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/mixins/redefineAnt.less';
.aRadioBtn();
.workOrder {
  padding: 20px 10px;
  background: #fff;
  margin: 10px;
  border-radius: 10px;
  min-height: calc(100vh - 130px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .description {
    background: #f7f7f7;
    padding: 20px 0 10px;
    margin-top: 10px;
  }
  .cloudUpload {
    width: 140px;
    height: 140px;
    background: rgba(243, 244, 249, 0.3);
    border-radius: 10px;
    border: 1px solid rgba(148, 152, 172, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #6782ff;
  }
  .uplodDesc {
    display: flex;
    flex-direction: column;
    background: #f9f9f9;
    border-radius: 5px;
    width: 350px;
    padding: 20px;
    margin-top: 14px;
  }
  .submit {
    width: 280px;
    margin: 0 auto;
  }
}
::v-deep {
  .el-radio.is-bordered {
    margin-right: 10px;
  }
  .el-radio.is-bordered.is-checked {
    background: fade(@primary-color, 10%);
    border-radius: 5px;
    border: 1px solid fade(@primary-color,  60%);
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: @primary-color;
  }
  .el-radio__input {
    display: none;
  }
}
</style>
